
// Angular modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Another modules
import { ClipboardModule } from 'ngx-clipboard'

// Environments
import { environment } from 'src/environments/environment'

// NgRx
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from './global-state/app.reducer';
import { metaReducers } from '../configs/libraries/ngrx-redux/constants/ngrx.constants'

import { FreshModule } from '../configs/libraries/fresh-chat/fresh-chat.module';
import { FeatureFlagsModule } from '@core/features-flags/feature-flags.module';
import { AliasRoutesComponent } from './alias-routes/alias-routes.component';

@NgModule({
  imports: [
    CommonModule,
    ClipboardModule,
    StoreModule.forRoot(appReducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),

    ClipboardModule,
    FreshModule,
    FeatureFlagsModule
  ],
  exports: [
    StoreModule,
    StoreDevtoolsModule,
    ClipboardModule,
    FreshModule
  ],
  declarations: [
    AliasRoutesComponent
  ],
})
export class CoreModule { }
