import { Injectable, inject } from '@angular/core';
import { AnalyticsBrowser } from '@segment/analytics-next'

import { environment } from "src/environments/environment";
import { IEventDataSchema,  Tracker, TrackingEvent } from '../interfaces/tracking-manager.interfaces';
import { AppFunctions } from '@shared/utils/app.functions';
import { IEventIdentifyDataDefinition } from '../interfaces/identify.event.data.interfaces';

@Injectable({
  providedIn: 'root'
})
export class SegmentService implements Tracker {
  //#region [---- [DEPENDENCIES] ----]
  private appFunctions: AppFunctions = inject(AppFunctions);
  //#endregion

  //#region [---- [PROPERTIES] ----]
  private analytics: AnalyticsBrowser;
  private isLocalhost: boolean;
  //#endregion
  
  //#region [---- [CONSTRUCTOR] ----]
  constructor() {
    this.isLocalhost = this.appFunctions.isLocalhost()
    this.initSdk();
  }
  //#endregion

  //#region [---- [PUBLIC LOGIC] ----]
  public trackEvent(eventName: TrackingEvent, data?: any):void {
    if(!this.isLocalhost) this.analytics.track(eventName, data);
  }

  public trackEventV2(eventName: TrackingEvent, eventData: IEventDataSchema):void {
    if(!this.isLocalhost) this.analytics.track(eventName, eventData);
  }

  public trackIdentifyEvents(identity:string,eventData:IEventIdentifyDataDefinition) {
    if(!this.isLocalhost) this.analytics.identify(identity, eventData);
  }

  public reset(){
    if(!this.isLocalhost) this.analytics.reset().then();
  }
  
  //#endregion

  //#region [---- [PRIVATE LOGIC] ----]
  private initSdk():void {
    if (!this.isLocalhost) {
      const SEGMENT_KEY =  {writeKey: environment.SEGMENT_WRITE_KEY}
      this.analytics = AnalyticsBrowser.load(SEGMENT_KEY)
    }
  }
  //#endregion
}
