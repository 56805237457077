import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineItemComponent } from '@pages/parents/pages/onboarding/pages/welcome/components/dumb/timeline-item/timeline-item.component';
import { ITimeLineWelcome } from '@pages/parents/pages/onboarding/pages/welcome/components/dumb/timeline-item/models/interfaces/timeline-item.interfaces';
import { StaticServeModule } from '@core/static-serve/static-serve.module';

@Component({
  selector: 'mdk-timeline',
  standalone: true,
  imports: [CommonModule,TimelineItemComponent,StaticServeModule],
  templateUrl: './timeline.component.html',
  styleUrls: ['./styles/timeline.component.mobile.scss']
})
export class TimelineComponent {
//#region [---- [INPUTS] ----]
  @Input() items: ITimeLineWelcome[]
//#endregion
}
